<template>
  <div id="box">
    <el-dialog
      :close-on-click-modal="false"
      title="会议信息"
      :visible.sync="visible"
      width="600px"
      :before-close="closeAddEdit"
    >
      <el-form size="small" ref="area" :model="form" label-width="100px">
        <el-form-item>
          <el-input
            style="height: 50px; font-size: 22px; border: 1px solid #0369fc"
            placeholder="输入名称"
            v-model="form.userId"
            @keyup.enter.native.prevent="save"
          >
            <el-button slot="prepend">用户名</el-button>
          </el-input>
          <el-input style="display: none"> </el-input>
        </el-form-item>
        <el-form-item v-if="roomType == 1">
          <el-input
            style="
              height: 50px;
              font-size: 22px;
              margin-top: 40px;
              border: 1px solid #0369fc;
            "
            placeholder="输入会议号"
            v-model="form.roomId"
          >
            <el-button slot="prepend">会议号</el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="save"
          >加入会议</el-button
        >
      </span>
    </el-dialog>
    <div class="box_content" v-if="indexVisible">
      <div class="box_content_border">
        <div class="box_content_border_tit">欢迎进入视频会商</div>
        <el-button
          style="
            width: 100%;
            height: 90px;
            font-size: 22px;
            background-color: #0369fc;
            border: 1px solid #0369fc;
          "
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="createRoom(1)"
          v-if="host == 'ncxsw' || host == 'ncx'"
          >创建会议</el-button
        >
        <el-input
          style="
            height: 90px;
            font-size: 22px;
            margin-top: 40px;
            border: 1px solid #0369fc;
          "
          placeholder="输入会议号"
          v-model="form.roomId"
        >
          <el-button @click="createRoom(2)" slot="append">加入会议</el-button>
        </el-input>
        <div class="explain">南昌县防汛指挥调度会议号: 6666</div>
        <div class="switchs">
          <el-checkbox v-model="microphoneShow">开启麦克风</el-checkbox>
          <el-checkbox v-model="videoShow">开启摄像头</el-checkbox>
          <!-- <el-checkbox v-model="openTranscribe">开启录制</el-checkbox> -->
        </div>
      </div>
    </div>
    <div
      @mouseenter="showBottom"
      @mouseleave="hideBottom2"
      class="videoparent"
      v-show="videoVisible"
      @click.stop="close"
      ref="video"
    >
      <div
        class="videobox"
        :class="[
          isActive == 1 ? 'quarter-div' : '',
          isActive == 2 ? 'quarter-div2' : '',
          isActive > 2 && isActive <= 4 ? 'quarter-div4' : '',
          isActive > 4 && isActive <= 6 ? 'quarter-div6' : '',
          isActive > 6 && isActive <= 9 ? 'quarter-div9' : '',
          isActive > 9 && isActive <= 12 ? 'quarter-div12' : '',
          isActive > 12 && isActive <= 16 ? 'quarter-div16' : '',
          isActive > 16 && isActive <= 20 ? 'quarter-div20' : '',
          isActive > 20 && isActive <= 25 ? 'quarter-div25' : '',
        ]"
        v-for="(item, index) in isActive"
        :key="index"
        :id="'videobox-' + index"
      >
        <div style="position: absolute; z-index: 4; width: 100%; height: 100%">
          <div class="videobox_userid">
            <img src="../assets/meeting/user.png" />
            <span style="margin-left: 10px; color: #ffffff">{{
              index == 0 ? form.userId : remoteStreamList[index - 1].getUserId()
            }}</span>
          </div>
          <div class="videobox-back" v-show="!videoShow"></div>
        </div>
      </div>
      <div
        id="videoparent_box"
        class="videoparent_box"
        v-show="shareVisible"
      ></div>
    </div>
    <div
      class="box_bottom"
      v-show="bottomVisible"
      @mouseenter="showBottom"
      @mouseleave="hideBottom2"
    >
      <div class="switch">
        <div class="switch_item">
          <div style="display: flex; height: 100%; align-items: center">
            <div class="switch_item_box" @click="openMicrophone">
              <img
                :src="
                  require(microphoneShow == true
                    ? '../assets/meeting/maikefeng.png'
                    : '../assets/meeting/maikefengguanbi.png')
                "
              />
              <div class="switch_item_tit">麦克风</div>
            </div>
            <i
              class="el-icon-caret-top"
              style="cursor: pointer"
              @click="switchMicrophone"
            ></i>
          </div>
        </div>
        <div class="switch_item">
          <div style="display: flex; height: 100%; align-items: center">
            <div class="switch_item_box" @click="openVideo">
              <img
                :src="
                  require(videoShow == true
                    ? '../assets/meeting/shexiangtou.png'
                    : '../assets/meeting/shexiangtou_guanbi.png')
                "
              />
              <div class="switch_item_tit">摄像头</div>
            </div>
            <i
              class="el-icon-caret-top"
              @click="switchCamera"
              style="cursor: pointer"
            ></i>
          </div>
        </div>
        <div
          class="switch_item"
          @click="handleStartScreenShare"
          v-show="meetingIndex == 0"
        >
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              padding: 1px 0 0 0;
              box-sizing: border-box;
            "
          >
            <div class="switch_item_box">
              <img src="../assets/meeting/wendang.png" />
              <div class="switch_item_tit">共享屏幕</div>
            </div>
          </div>
        </div>
        <div
          class="switch_item"
          @click="destroyShareLocalStream"
          v-show="meetingIndex == 1"
        >
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              padding: 1px 0 0 0;
              box-sizing: border-box;
            "
          >
            <div class="switch_item_box">
              <img src="../assets/meeting/wendang2.png" />
              <div class="switch_item_tit">取消共享屏幕</div>
            </div>
          </div>
        </div>
        <div
          class="switch_item"
          @click="startRecording"
          v-show="startTranscribe"
        >
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              padding: 1px 0 0 0;
              box-sizing: border-box;
            "
          >
            <div class="switch_item_box">
              <img src="../assets/meeting/agora.png" />
              <div class="switch_item_tit">开始录制</div>
            </div>
          </div>
        </div>
        <div class="switch_item" @click="stopRecording" v-show="stopTranscribe">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              padding: 1px 0 0 0;
              box-sizing: border-box;
            "
          >
            <div class="switch_item_box">
              <img src="../assets/meeting/agora2.png" />
              <div class="switch_item_tit">关闭录制</div>
            </div>
          </div>
        </div>
        <!-- <div class="switch_item">
          <div style="display: flex; height: 100%; align-items: center">
            <div class="switch_item_box" @click="classss">
              <img :src="require('../assets/meeting/shexiangtou.png')" />
              <div class="switch_item_tit">静音</div>
            </div>
            <i
              class="el-icon-caret-top"
              @click="switchCamera"
              style="cursor: pointer"
            ></i>
          </div>
        </div> -->
        <!-- <div class="switch_item" @click="Refresh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              padding: 1px 0 0 0;
              box-sizing: border-box;
            "
          >
            <div class="switch_item_box">
              <img src="../assets/meeting/Refresh.png" />
              <div class="switch_item_tit">刷新</div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <button class="box_bottom_btn" @click="finishCalls">结束会议</button> -->
      <!-- <button
        class="box_bottom_btn"
        v-if="this.userInfo == 1"
        @click="finishCalls"
      >
        结束会议
      </button> -->
      <button class="box_bottom_btn"  @click="finishCall">
        退出会议
      </button>
      <!-- <el-button>离开会议</el-button> -->
    </div>
    <div
      class="microphone_box"
      v-show="microphoneVisible"
      @mouseenter="showBottom"
      @mouseleave="hideBottom"
    >
      <div style="color: #a8b2aa">麦克风</div>
      <el-select
        style="width: 100%; margin-top: 10px"
        v-model="microphoneValue"
        placeholder="请选择"
        :popper-append-to-body="false"
        @change="changeMicrophone"
      >
        <el-option
          v-for="item in microphoneList"
          :key="item.deviceId"
          :label="item.label"
          :value="item.deviceId"
        >
        </el-option>
      </el-select>
      <div style="color: #a8b2aa; margin-top: 40px">扬声器</div>
      <el-select
        style="width: 100%; margin-top: 10px"
        v-model="speakValue"
        placeholder="请选择"
        @change="changeSpeak"
      >
        <el-option
          v-for="item in speakList"
          :key="item.deviceId"
          :label="item.label"
          :value="item.deviceId"
        >
        </el-option>
      </el-select>
    </div>
    <div
      class="camera_box"
      v-show="cameraVisible"
      @mouseenter="showBottom"
      @mouseleave="hideBottom"
    >
      <div style="color: #a8b2aa">摄像头</div>
      <el-select
        style="width: 100%; margin-top: 10px"
        v-model="cameraValue"
        placeholder="请选择"
        :popper-append-to-body="false"
        @change="changeCamera"
      >
        <el-option
          v-for="item in cameraList"
          :key="item.deviceId"
          :label="item.label"
          :value="item.deviceId"
        >
        </el-option>
      </el-select>
      <div style="color: #a8b2aa; margin-top: 40px">分辨率</div>
      <el-select
        style="width: 100%; margin-top: 10px"
        v-model="resolutionValue"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in resolutionRatioList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RecordRTC from "recordrtc";
import TRTC from "trtc-js-sdk";
import LibGenerateTestUserSig from "@/utils/lib-generate-test-usersig.min.js";
export default {
  data() {
    return {
      roomType: 1,
      sdkAppId: 1600041452,
      // sdkAppId: 1400815675,
      userSig: "",
      // SecretKey:
      //   "b34fc69dcf7bfbe076d0b47aaeba4fcdd2c5fc4a6afa9b6b58e58b44a05f976a",
      SecretKey:
      "7c8c3b686b60e261cbccee568060f9e21d49bd8afc8dc5362c4cb1e9e52eccc8",
      client: "", // 客户端服务
      client2: "", // 客户端服务
      remoteStream: "", // 远方播放流
      localStream: "", // 本地流
      remoteStreamList: [], // 远端小视频
      visible: false,
      indexVisible: true,
      videoVisible: false,
      bottomVisible: false,
      form: {
        userId: "",
        roomId: "6666",
      },
      videoShow: true,
      microphoneShow: false,
      isActive: 1,
      videoListShow: false,
      index: 0,
      shareClient: null,
      shareLocalStream: null,
      isShareJoined: false,
      isSharePublished: false,
      shareStream: null,
      microphoneValue: "",
      cameraValue: "",
      speakValue: "",
      microphoneVisible: false,
      cameraVisible: false,
      microphoneList: [],
      cameraList: [],
      speakList: [],
      resolutionValue: 0,
      resolutionRatioList: [
        {
          id: 0,
          name: "高清",
        },
      ],
      meetingIndex: 0,
      shareVisible: false,
      // openMike: false,
      // openCamera: true,
      openTranscribe: false,
      videoStart: false,
      recorder: null,
      fileName: new Date().getTime(),
      startTranscribe: false,
      stopTranscribe: false,
      userInfo: 0,
      host: "",
      isVisible: true,
    };
  },
  beforeDestroy() {
    if (this.shareStream != "" && this.shareStream != null) {
      // 取消推流
      this.client2.unpublish(this.shareStream);
      // 停止采集屏幕分享
      this.shareStream.close();
      this.shareVisible = false;
      this.meetingIndex = 0;
    }
    if (this.localStream != "") {
      // // 关闭摄像头
      if (this.videoShow == true) {
        const videoTrack = this.localStream.getVideoTrack();
        if (videoTrack) {
          this.localStream.removeTrack(videoTrack);
          // 停止采集，关闭摄像头
          videoTrack.stop();
        }
      }
      if (this.microphoneShow == true) {
        const audioTrack = this.localStream.getAudioTrack();
        if (audioTrack) {
          this.localStream.removeTrack(audioTrack);
          audioTrack.stop();
        }
      }
      this.localStream.close();
      this.client.leave();
      let that = this;
      setTimeout(() => {
        that.client.destroy();
      }, 500);
    }
  },
  mounted() {
    this.getCamera();
    this.getMicrophone();
    this.getSpeakers();
    this.getUserName();
  },
  methods: {
    getUrlParameter(name) {
      // 创建URL对象
      var url = new URL(window.location.href);
      // 使用searchParams获取URL参数
      var param = url.searchParams.get(name);
      return param;
    },
    getUserName() {
      let currentUrl = window.location.href;
      let token = this.getUrlParameter("token"); // 返回 'value'
      axios({
        headers: {
          token,
        },
        method: "post",
        url: "/ncx/gtw/custom/user/current/info",
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 10000) {
            this.form.userId = res.data.queryData.nickName;
            this.host = res.data.queryData.accountName;
            console.log(this.host);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async startCall(role) {
      if (role == "anchor") {
        this.form.userId = this.form.userId + " - 主持人";
        this.startTranscribe = true;
        this.userInfo = 1;
      }
      const config = this.genTestUserSig(this.form.userId);
      const sdkAppId = config.sdkAppId;
      const userSig = config.userSig;
      this.userSig = config.userSig;
      const userId = this.form.userId;
      this.client = TRTC.createClient({
        mode: "rtc",
        sdkAppId,
        userId,
        userSig,
        role,
      });
      // 1.监听事件
      this.client.on("stream-added", (event) => {
        const remoteStream = event.stream;
        this.remoteStreamList.push(remoteStream);
        //订阅远端流
        this.client.subscribe(remoteStream);
        this.isActive = this.remoteStreamList.length + 1;
      });
      this.client.on("stream-subscribed", (event) => {
        // 远端流订阅成功
        const remoteStream = event.stream;
        // 播放远端流，传入的元素 ID 必须是页面里存在的 div 元素
        if (remoteStream.getType() === "main") {
          this.index++;
          remoteStream.play("videobox-" + this.index);
        } else if (remoteStream.getType() === "auxiliary") {
          this.shareVisible = true;
          remoteStream.play("videoparent_box");
        }
        this.isVisible = true;
      });

      this.client.on("stream-removed", (event) => {
        // this.index = this.remoteStreamList.length - 1;
        const { stream: remoteStream } = event;
        if (remoteStream.getType() === "main") {
          remoteStream.stop();
          const index = this.remoteStreamList.indexOf(remoteStream);
          if (index >= 0) {
            this.remoteStreamList.splice(index, 1);
            this.isActive = this.isActive - 1;
            this.index = this.index - 1;
            if (this.isVisible) {
              this.Refresh();
              this.isVisible = false;
            }
          }
        } else if (remoteStream.getType() === "auxiliary") {
          this.shareVisible = false;
          this.Refresh();
        }
        // console.log(this.index);
        // console.log(this.remoteStreamList.length);
      });
      // 2.进房成功后开始推流
      let roomId = Number(this.form.roomId);
      try {
        await this.client.join({ roomId });
        let that = this;
        this.localStream = TRTC.createStream({
          userId,
          audio: true,
          video: true,
        });
        this.localStream.setVideoProfile("480p");
        await this.localStream.initialize();
        // // 关闭摄像头
        // if (this.videoShow == false) {
        //   const videoTrack = this.localStream.getVideoTrack();
        //   if (videoTrack) {
        //     this.localStream.removeTrack(videoTrack);
        //     // 停止采集，关闭摄像头
        //     videoTrack.stop();
        //   }
        // }
        // if (this.microphoneShow == true) {
        //   const audioTrack = this.localStream.getAudioTrack();
        //   if (audioTrack) {
        //     this.localStream.removeTrack(audioTrack);
        //     audioTrack.stop();
        //   }
        // }
        this.sum();
        // 播放本地流
        if (document.getElementById("videobox-0").children.length <= 1) {
          await this.localStream.play("videobox-0");
        }
        await this.client.publish(this.localStream);
      } catch (error) {
        console.error(error);
      }
      // setTimeout(() => {
      //   this.Refresh();
      // }, 1000);
    },
    close() {
      this.microphoneVisible = false;
      this.cameraVisible = false;
    },
    // 获取用户签名 - 前端测试使用
    genTestUserSig(userID) {
      const SDKAPPID = this.sdkAppId;
      const EXPIRETIME = 604800;
      const SECRETKEY = this.SecretKey;
      if (SDKAPPID === "" || SECRETKEY === "") {
        alert(
          "请先配置好您的账号信息： SDKAPPID 及 SECRETKEY " +
            "\r\n\r\nPlease configure your SDKAPPID/SECRETKEY in js/debug/GenerateTestUserSig.js"
        );
      }
      const generator = new LibGenerateTestUserSig(
        SDKAPPID,
        SECRETKEY,
        EXPIRETIME
      );
      const userSig = generator.genTestUserSig(userID);
      return {
        sdkAppId: SDKAPPID,
        userSig: userSig,
      };
    },
    // 退出会议
    finishCall() {
      this.$confirm("是否退出会议?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          if (this.shareStream != "" && this.shareStream != null) {
            // 取消推流
            this.client2.unpublish(this.shareStream);
            // 停止采集屏幕分享
            this.shareStream.close();
            this.shareVisible = false;
            this.meetingIndex = 0;
          }
          // 停止本地流预览
          this.localStream.close();
          this.client.leave();
          this.isVisible = false;
          // 退房成功，如果没有调用 client.destroy()，可再次调用 client.join 重新进房开启新的通话
          // 调用 destroy() 结束当前 client 的生命周期
          let that = this;
          setTimeout(() => {
            that.isActive = that.isActive - 1;
            that.index = 0;
            that.client.destroy();
            that.$message("退出成功");
            that.videoShow = true;
            that.microphoneShow = false;
            that.$nextTick(() => {
              that.localStream = null;
              that.client = null;
              // this.client.destroy();
              // that.client = ""; // 客户端服务
              // that.client2 = ""; // 客户端服务
              // that.remoteStream = ""; // 远方播放流
              // that.localStream = ""; // 本地流
              // that.remoteStreamList = []; // 远端小视频
              // (that.shareClient = null),
              //   (that.shareLocalStream = null),
              //   (that.shareStream = null),
              that.form = {
                userId: "",
                roomId: "6666",
              };
              that.videoVisible = false;
              that.bottomVisible = false;
              that.indexVisible = true;
            });
          }, 500);
          // console.log(this.remoteStreamList.length);
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
      // this.$confirm("是否选择结束会议?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     // 停止本地流预览
      //     this.localStream.close();
      //     this.client.leave();
      //     // 退房成功，如果没有调用 client.destroy()，可再次调用 client.join 重新进房开启新的通话
      //     // 调用 destroy() 结束当前 client 的生命周期
      //     this.client.destroy();
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消结束会议",
      //     });
      //   });
    },
    // 结束会议
    finishCalls() {
      this.$confirm("是否选择结束会议?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          let time = String(new Date().getTime());
          time = Number(time.slice(0, -3));
          const Nonce = Math.ceil(Math.random() * time);
          console.log(time);
          axios({
            // headers: {
            //   token: token,
            // },
            method: "get",
            url: `/api`,
            params: {
            Action: "DismissRoom",
            Version: "2019-07-22",
            Region: 'ap-beijing',
            Timestamp: time,
            SdkAppId: this.sdkAppId,
            RoomId: this.form.roomId,
            Nonce: Nonce,
            SecretId: 'AKID99CRHxPj5NZdV8ZloiU6VihRxFKFCHDw',
            Signature: 'e5RltoX2IK7fFkeMq7A0f7LL9k7GCD6m',
            }
          })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "已取消结束",
          });
        });
    },
    createRoom(id) {
      this.roomType = id;
      if (id == 2) {
        if (this.form.roomId == "") {
          this.$message("请填写会议号");
        } else {
          this.$nextTick(() => {
            this.visible = true;
          });
        }
      } else {
        this.$nextTick(() => {
          // this.form = {
          //   userId: "",
          //   roomId: "6666",
          // };
          this.visible = true;
        });
      }
    },
    closeAddEdit() {
      this.visible = false;
      // this.form = {
      //   userId: this.form.userId,
      //   roomId: "6666",
      // };
    },
    save() {
      if (this.roomType == 1) {
        if (this.form.userId != "" && this.form.roomId != "") {
          this.visible = false;
          this.indexVisible = false;
          this.videoVisible = true;
          this.$nextTick(() => {
            this.isActive = 1;
            this.startCall("anchor");
          });
        } else {
          this.$message("请填写会议信息");
        }
      } else {
        if (this.form.userId != "") {
          this.visible = false;
          this.indexVisible = false;
          this.videoVisible = true;
          this.$nextTick(() => {
            this.isActive = 1;
            this.startCall("audience");
          });
        } else {
          this.$message("请填写名称");
        }
      }
    },
    showBottom() {
      this.bottomVisible = true;
      if (this.microphoneVisible == true) {
        this.microphoneVisible == true;
      }
      if (this.cameraVisible == true) {
        this.cameraVisible == true;
      }
    },
    hideBottom() {
      this.microphoneVisible = false;
      this.cameraVisible = false;
    },
    hideBottom2() {
      this.bottomVisible = false;
      // this.microphoneVisible = false;
      // this.cameraVisible = false;
    },
    openMicrophone() {
      this.microphoneShow = !this.microphoneShow;
      if (this.microphoneShow == true) {
        this.localStream.unmuteAudio();
      } else {
        this.localStream.muteAudio();
      }
    },
    openVideo() {
      this.videoShow = !this.videoShow;
      let element = document.getElementById("videobox-0");
      if (this.videoShow == true) {
        this.localStream.unmuteVideo();
      } else {
        console.log(element.children);
        this.localStream.muteVideo();
        // element.style.backgroundColor = "#000000";
        // element.style.zIndex = 10;
      }
    },
    sum() {
      setTimeout(() => {
        if (this.microphoneShow === true) {
          this.localStream.unmuteAudio();
        } else {
          this.localStream.muteAudio();
        }
        if (this.videoShow === true) {
          this.localStream.unmuteVideo();
        } else {
          this.localStream.muteVideo();
        }
      }, 500);
    },
    Refreshs() {
      this.client = ""; // 客户端服务
      this.client2 = ""; // 客户端服务
      this.remoteStream = ""; // 远方播放流
      this.localStream = ""; // 本地流
      this.remoteStreamList = []; // 远端小视频
      (this.shareClient = null),
        (this.shareLocalStream = null),
        (this.shareStream = null),
        (this.index = 0);
      this.isActive = 1;
    },
    Refresh() {
      this.client = ""; // 客户端服务
      this.client2 = ""; // 客户端服务
      this.remoteStream = ""; // 远方播放流
      this.localStream = ""; // 本地流
      this.remoteStreamList = []; // 远端小视频
      (this.shareClient = null),
        (this.shareLocalStream = null),
        (this.shareStream = null),
        (this.index = 0);
      this.isActive = 1;
      this.$nextTick(() => {
        this.startCall();
      });
    },
    // 结束屏幕分享
    async destroyShareLocalStream() {
      // 取消推流
      await this.client2.unpublish(this.shareStream);
      // 停止采集屏幕分享
      this.shareStream.close();
      this.shareVisible = false;
      this.meetingIndex = 0;
      this.Refresh();
    },
    async initShareClient() {
      this.client2 = TRTC.createClient({
        mode: "rtc",
        sdkAppId: this.sdkAppId, // 填写您的 sdkAppId
        userId: this.form.userId, // 填写您的 userId
        userSig: this.userSig, // 填写 userId 对应的 userSig
      });
      await this.client2.join({ roomId: Number(this.form.roomId) });
      this.shareStream = TRTC.createStream({
        userId: this.form.userId,
        screen: true, // 采集屏幕分享
      });
      this.shareStream.on("screen-sharing-stopped", async (event) => {
        // 取消推流
        await this.client2.unpublish(this.shareStream);
        // 停止采集屏幕分享
        this.shareStream.close();
        this.meetingIndex = 0;
        this.shareVisible = false;
        this.Refresh();
      });
      await this.shareStream.initialize();
      this.meetingIndex = 1;
      await this.client2.publish(this.shareStream, { isAuxiliary: true }); // 将 isAuxiliary 设为 true，将以辅流的形式进行推流。
    },
    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      await this.initShareClient();
    },
    getMicrophone() {
      TRTC.getMicrophones().then((devices) => {
        this.microphoneList = JSON.parse(JSON.stringify(devices));
        this.microphoneValue = devices[0].deviceId;
      });
    },
    getCamera() {
      TRTC.getCameras().then((devices) => {
        this.cameraList = JSON.parse(JSON.stringify(devices));
        this.cameraValue = this.cameraList[0].deviceId;
      });
    },
    getSpeakers() {
      TRTC.getSpeakers().then((devices) => {
        this.speakList = JSON.parse(JSON.stringify(devices));
        this.speakValue = this.speakList[0].deviceId;
      });
    },
    switchMicrophone() {
      this.cameraVisible = false;
      this.microphoneVisible = !this.microphoneVisible;
    },
    switchCamera() {
      this.microphoneVisible = false;
      this.cameraVisible = !this.cameraVisible;
    },
    changeCamera(value) {
      let cameraId = value;
      this.localStream.switchDevice("video", cameraId).then(() => {
        console.log("switch camera success");
      });
    },
    changeMicrophone(value) {
      let micId = value;
      this.localStream.switchDevice("audio", micId).then(() => {
        console.log("switch microphone success");
      });
    },
    changeSpeak(value) {
      let micId = value;
      this.localStream.setAudioOutput(micId).then(() => {
        console.log("switch speaker success");
      });
    },
    /**
     * 开始录制
     */
    startRecording(callback) {
      this.captureScreen((screenStream) => {
        this.addStreamStopListener(screenStream, () => {
          console.log("流停止监听");
          this.$emit("streamStop", {});
          // this.stopRecording();
        });
        var options = {
          type: "video",
          mimeType: "video/webm",
          disableLogs: false,
          getNativeBlob: false, // enable it for longer recordings
          ignoreMutedMedia: false,
        };
        // this.video.srcObject = screenStream;
        this.recorder = RecordRTC(screenStream, options);
        this.recorder.startRecording();
        this.recorder.screen = screenStream;
        this.videoStart = true;
        this.startTranscribe = false;
        this.stopTranscribe = true;
        // callback(true);
      });
    },
    /**
     * 停止录制
     */
    stopRecording(callback) {
      this.recorder.stopRecording(() => {
        // this.video.src = this.video.srcObject = null;
        // this.video.src = URL.createObjectURL(this.recorder.getBlob());
        const url = URL.createObjectURL(this.recorder.getBlob());
        const a = document.createElement("a");
        let videoFile = new File(
          [this.recorder.getBlob()],
          "录屏" + this.fileName + ".mp4",
          {
            type: "video/mp4",
          }
        );
        let downloadUrl = URL.createObjectURL(videoFile);
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = "录屏" + this.fileName + ".mp4";
        a.click();
        this.recorder.screen.stop();
        this.recorder.destroy();
        this.recorder = null;
        this.videoStart = false;
        this.startTranscribe = true;
        this.stopTranscribe = false;
        // callback(false);
      });
    },
    //初始化
    captureScreen(callback) {
      if (navigator.getDisplayMedia) {
        //录制结束,文件下载
        navigator
          .getDisplayMedia({
            video: true,
          })
          .then((screenStream) => {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((mic) => {
              screenStream.addTrack(mic.getTracks()[0]);
              callback(screenStream);
            });
          })
          .catch(function (error) {
            console.log("error", error);
          });
      } else if (navigator.mediaDevices.getDisplayMedia) {
        navigator.mediaDevices
          .getDisplayMedia({
            video: true,
          })
          .then((screenStream) => {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((mic) => {
              screenStream.addTrack(mic.getTracks()[0]);
              callback(screenStream);
            });
          })
          .catch(function (error) {
            console.log("error", error);
          });
      } else {
        var error = "getDisplayMedia API are not supported in this browser.";
        console.log("error", error);
        alert(error);
      }
    },
    classss() {
      TRTC.muteRemoteAudio("*", true);
    },
    //流监听
    addStreamStopListener(stream, callback) {
      stream.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      stream.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      stream.getTracks().forEach(function (track) {
        track.addEventListener(
          "ended",
          function () {
            callback();
            callback = function () {};
          },
          false
        );
        track.addEventListener(
          "inactive",
          function () {
            callback();
            callback = function () {};
          },
          false
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
#box {
  width: 100%;
  height: 100vh;
  background-color: #010101;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.box_content {
  width: 430px;
  height: 475px;
}
.box_content_border {
  width: 100%;
  height: 100%;
  background-color: #181b22;
  border-radius: 30px;
  padding: 0 35px;
  text-align: center;
  position: relative;
}
.box_content_border_tit {
  font-style: italic;
  width: 100%;
  color: #ffffff;
  line-height: 160px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 5px;
  background-image: -webkit-linear-gradient(left, #9eeef8, #077bfc);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
/deep/.el-popper {
  background-color: #1d2029 !important;
  border: 1px solid #262a34 !important;
}
/deep/ .el-select-dropdown {
  background-color: #1d2029;
  border: 1px solid #262a34;
  // top: -205px !important;
}
.poperssss {
  top: -205px !important;
}
/deep/ .popper__arrow {
  display: none;
}
/deep/ .el-input {
  height: 90px !important;
}
/deep/.el-input__inner {
  height: 100% !important;
  background-color: #19253a;
  border: none;
  // display: block;
}
/deep/.el-input-group__append {
  background-color: #0369fc;
  color: #ffffff;
  border: 1px solid #0369fc;
  cursor: pointer;
  // color: #1D2029;
}
/deep/.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #2e323d !important;
}
/deep/ .el-input-group__prepend {
  background-color: #0369fc;
  color: #ffffff;
  border: 1px solid #0369fc;
  cursor: pointer;
}
/deep/.el-dialog {
  background-color: #181b22;
}
/deep/ .el-dialog__header {
  border: none;
}
/deep/.el-dialog__title {
  color: #ffffff;
}
/deep/ .el-form-item__content {
  margin-left: 0 !important;
}
/deep/.el-input--suffix {
  height: 40px !important;
}
.videoparent {
  width: 90vw;
  height: 99vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  // justify-content: space-evenly;
}
.videoparent_box {
  z-index: 9;
  position: absolute;
  width: 100%;
  height: 100%;
}
.videobox {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #2d3033;
  border: 1px solid #3c3f44;
  box-sizing: border-box;
}
#videobox-0 .videobox-back {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  z-index: 6;
  border: 1px solid #3c3f44;
}
.videobox_userid {
  z-index: 7;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #181819;
  // z-index: 10;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.videobox_userid img {
  width: 35px;
  height: 35px;
}
.quarter-div25 {
  width: 20%;
  height: 20%;
}
.quarter-div20 {
  width: 20%;
  height: 25%;
}
.quarter-div16 {
  width: 25%;
  height: 25%;
}
.quarter-div12 {
  width: 25%;
  height: 33.33333%;
}
.quarter-div9 {
  width: 33.33333%;
  height: 33.33333%;
}
.quarter-div6 {
  width: 33.33333%;
  height: 50%;
}
.quarter-div4 {
  width: 50%;
  height: 50%;
}
.quarter-div2 {
  width: 50%;
  height: 100%;
}
.quarter-div {
  width: 100%;
  height: 100%;
}
.box_bottom {
  z-index: 11;
  display: none;
  width: 100%;
  position: absolute;
  height: 80px;
  background-color: #1d2029;
  bottom: 2px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch {
  // width: 500px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}
.switch img {
  width: 28px;
  height: 28px;
  // border-radius: 50%;
  margin-top: 20px;
}
.switch_item_tit {
  font-size: 12px;
  color: #adb6cc;
  line-height: 25px;
}
.switch_item {
  width: 100px;
  height: 100%;
  text-align: center;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
}
.switch_item:hover {
  box-sizing: border-box;
  border-bottom: 3px solid #0369fc;
}
.switch_item_box {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.el-icon-caret-top {
  color: #adb6cc;
  font-size: 12px;
  margin-left: 10px;
}
.box_bottom_btn {
  width: 100px;
  height: 40px;
  margin-right: 30px;
  border: 1px solid #ff2e2e;
  background-color: #1d2029;
  color: #ff2e2e;
  font-size: 14px;
  border-radius: 6px;
}
/deep/ .el-message-box {
  background-color: #1d2029 !important;
  border: none !important;
}
.microphone_box {
  z-index: 11;
  width: 300px;
  position: absolute;
  // height: 250px;
  background-color: #1d2029;
  bottom: 100px;
  left: 20px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.camera_box {
  z-index: 11;
  width: 300px;
  position: absolute;
  // height: 250px;
  background-color: #1d2029;
  bottom: 100px;
  left: 40px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
/deep/.el-checkbox__label {
  color: #ffffff;
}
.explain {
  position: absolute;
  width: 100%;
  text-align: right;
  right: 35px;
  color: #ffffff;
  font-size: 14px;
  line-height: 30px;
}
.switchs {
  position: absolute;
  margin-top: 40px;
  bottom: 20px;
  left: 105px;
}
</style>